import { __decorate } from "tslib";
import { Component, Ref, Watch } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import { DingtalkIntegrated, WechatIntegrated, } from './addConfig-formItem/index';
import { SourceType, } from '../../../typings/organization/org-manage';
import AddEditOrgBase from './add-edit-org-base';
import * as Utils from '@/common/utils';
let OrgConfigForm = class OrgConfigForm extends AddEditOrgBase {
    onFormChange() {
        Utils.trim(this.form);
    }
    showConfigRule() {
        super.showConfigRule();
    }
    /**
     * 组织维护方式变化
     * */
    onMaintainWayChange() {
        super.setRules();
    }
    async submit() {
        const isValid = this.$refs.cForm.validate();
        if (!isValid) {
            return {
                success: false,
            };
        }
        const params = super.paramsFormator();
        const res = await OrgManageService.addOrg(params);
        if (res.success) {
            this.$message.success('添加成功');
            this.cancel();
            this.$emit('reload');
            if (this.form.sourceType === SourceType.DingtalkMaintain) {
                OrgManageService.registerCallbackUrl({ orgId: res.data?.id }).catch(() => { });
            }
            return res;
        }
    }
    cancel() {
        this.cForm && this.cForm.resetFields();
    }
    created() {
        this.cancel();
    }
};
__decorate([
    Ref()
], OrgConfigForm.prototype, "cForm", void 0);
__decorate([
    Watch('form', { immediate: true, deep: true })
], OrgConfigForm.prototype, "onFormChange", null);
OrgConfigForm = __decorate([
    Component({
        name: 'OrgConfigForm',
        components: {
            cForm: () => import('@/components/shared/CForm/Form.vue'),
            cFormItem: () => import('@/components/shared/CForm/FormItem.vue'),
            DingtalkIntegrated,
            WechatIntegrated,
            ConfigRule: () => import('./config-rule/index.vue'),
        },
    })
], OrgConfigForm);
export default OrgConfigForm;
